<template>
    <v-app>
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-header">
                        <div class="card-title pt-3 px-3 d-flex justify-content-between">
                        <div class="breadcrumb-left">
                            <h4>
                            {{title}} page
                            </h4>
                            <div class="breadcrumb-sub-header">
                            <router-link to="/dashboard">Dashboard </router-link> \
                            {{title}} page
                            </div>
                        </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <v-row>
                            <v-col cols="12" md="12">
                            <v-text-field
                                v-model="page.name"
                                :error="$v.page.name.$error"
                                outlined
                                dense
                            >
                                <template v-slot:label>
                                Name <span class="text-danger">*</span>
                                </template>
                            </v-text-field>
                            <span class="text-danger" v-if="$v.page.name.$error">Name is required</span>
                            <span class="text-danger" v-if="errors.name" >* {{ errors.name[0] }}</span>
                            </v-col>


                            <v-col cols="12">
                            Description <span class="text-danger">*</span>
                            <ckeditor
                                :config="editorConfig"
                                v-model="page.description"
                            >
                            </ckeditor>
                            <span class="text-danger" v-if="$v.page.description.$error">This information is required</span>
                            <span class="text-danger" v-if="errors.description" >* {{ errors.description[0] }}</span>
                            </v-col>

                            <v-col cols="12" md="6">
                            Status
                            <v-switch
                                v-model="page.is_active"
                                :label="page.is_active ? 'Active' : 'Inactive'"
                            ></v-switch>
                            </v-col>

                            <v-col cols="12" sm="12" md="12" class="text-right">
                                <v-btn
                                    x-large
                                    :loading="loading"
                                    @click="createOrUpdate"
                                    dark
                                    >Save</v-btn
                                >
                            </v-col>
                        </v-row>                  
                    </div>
                </div>
            </div>
        </div>
    </v-app>    
</template>

<script>
import PageService from "@/services/cms/page/PageService";
import {required, numeric} from "vuelidate/lib/validators";

const pageService = new PageService();

export default {
  validations: {
    page: {
        name: { required },
        description: { required },
        is_active: { required }
    }
  },
  data() {
    return{
      loading: false,
      dialog: false,
      edit: false,
      title: '',
      errors: [],
      page: {
        name: '',
        description: '',
        position: null,
        is_active: true,
    },
      editorConfig: {
        toolbar: [ [ 'Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'Link', 'NumberedList', 'BulletedList' ] ],
        versionCheck: false
      }
    }
  },
  methods:{
    getPageDetail(){
        if (this.pageId != null || this.pageId != undefined) {
            this.title = "Edit";
            this.edit = true;
            pageService
            .show(this.pageId)
            .then((res) => {
                this.page = res.data.page;
            })
            .catch((err) => {

            });
        }else{
            this.title = "Add"
            this.edit = false;
        }
    },
    createOrUpdate() {
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      }
      else {
        this.loading = true;
        if (!this.edit) this._create();
        else this.update();
      }
    },
    _create: function () {
      pageService
          .create(this.page)
          .then(response => {
            this.$snotify.success("Page added");
            this.loading = false;
            this.$tabs.close();
          })
          .catch((error) => {
            this.loading = false;
            this.errors = error.errors;
            this.$snotify.error("Oops something went wrong");
          });

    },
    update: function () {
      pageService
          .update(this.page.id, this.page)
          .then((response) => {
            this.$snotify.success("Page updated");
            this.loading = false;
            this.$tabs.close();
          })
          .catch((error) => {
            this.loading = false;
            this.errors = error.errors;
            this.$snotify.error("Oops something went wrong");
          });
    }
  },
  computed:{
    pageId() {
      return this.$route.params.pageId;
    },
  },
  mounted(){
    this.getPageDetail();
  }
}
</script>

<style scoped>
  #logo_preview{
    height: 100px;
    width: 100px;
    object-fit: contain;
  }
</style>
